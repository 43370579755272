"use strict";

angular.module("informaApp")
    .service("FileDownloader", ['$q', '$http', 'ConstantsSvc', function ($q, $http, ConstantsSvc) {
        function downloadFile(request, newName, callback) {
            request.then(function (res) {
                var fileName = newName || getFileNameFromHttpResponse(res);
                
                var blob = new Blob([res.data]);

                if (!downloadFileIE(blob, fileName)) {
                    const downloadFile = isFileSystemSupported() ? downloadFileChrome : downloadFileFirefox;

                    downloadFile(blob, fileName);
                }

                if (callback) {
                    callback(true, undefined, fileName);
                }
            }).catch(function (e) {
                console.log(`downloadFile - catch:error `, e, request, newName, callback);
                if (callback) {
                    callback(false, e);
                }
            });
        }

        function isFileSystemSupported() {
            const requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem;

            return !!requestFileSystem;
        }

        function downloadFileFirefox(blob, fileName) {
            const downloadLink = document.createElement('a');
            downloadLink.style.display = 'none';
            const fileBlob = new Blob([blob], {type: ConstantsSvc.MIME_TYPES.XLSX});
            const url = window.URL.createObjectURL(fileBlob);
            downloadLink.href = url;
            downloadLink.download = fileName;

            document.body.appendChild(downloadLink);
            downloadLink.click();

            setTimeout(() => {
                document.body.removeChild(downloadLink);
                window.URL.revokeObjectURL(url);
            }, 100);
        }

        function downloadFileIE(blob, fileName) {
            if (window.navigator.msSaveBlob) { // IE
                window.navigator.msSaveOrOpenBlob(blob, fileName);

                return true;
            }

            return false;
        }

        function downloadFileChrome(blob, fileName) {
            var requestedBytes = blob.size;

            window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem;

            window.requestFileSystem(window.TEMPORARY, requestedBytes, function (fs) {
                fs.root.getFile(fileName, {create: true}, function (fileEntry) {
                    fileEntry.createWriter(getCreateWriterCallback(blob, fileName, fileEntry), _.noop);
                }, _.noop);
            }, _.noop);
        }

        function getCreateWriterCallback(blob, fileName, fileEntry) {
            return function (fileWriter) {
                fileWriter.addEventListener("writeend", function onEnd() {
                    fileWriter.removeEventListener('writeend', onEnd);
                    writeData();
                }, false);

                fileWriter.truncate(0);

                function writeData() {
                    fileWriter.addEventListener("writeend", function onEnd() {
                        fileWriter.removeEventListener('writeend', onEnd);
                        openUrl(fileEntry.toURL(), fileName);
                    }, false);

                    fileWriter.write(blob);
                }
            }
        }

        function openUrl(url, fileName) {
            var anchor = angular.element('<a/>');

            anchor.attr({
                href: url,
                target: '_blank',
                download: fileName
            })[0].click();
        }

        function getFileNameFromHttpResponse(httpResponse) {
            var contentDispositionHeader = httpResponse.headers('Content-Disposition');
            var result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
            return result.replace(/"/g, '');
        }

        return {
            download: function (request, newName, callback) {

                downloadFile(request, newName, callback);
            },
            downloadPromise: function (request, newName) {
                return $q(function (resolve, reject) {
                    downloadFile(request, newName, function (result, e, fileName) {
                        if (result){
                            resolve(fileName);
                        } else {
                            reject(e);
                        }
                    });
                });
            },
            downloadBinaryFile: function (requestOptions, filename, callback) {
                var httpRequest = {
                    headers: {
                        'Content-type': 'application/json'
                    },
                    responseType: 'arraybuffer'
                };
                
                const cookies = document.cookie.split('; ');
                for (let i = 0; i < cookies.length; i++) {
                    const cookie = cookies[i];
                    if (cookie.indexOf('token=') === 0) {
                        httpRequest.headers = {
                        ...httpRequest.headers,
                        Authorization: `Bearer ${cookie.split('=')[1]}`
                        };
                        break;
                    }
                }
                
                var request = $http(_.merge({}, httpRequest, requestOptions));

                return this.download(request, filename, callback);
                // return this.downloadPromise(request, filename);
            }
        }
    }]);